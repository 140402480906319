.catalog-filter-footer {
    .btn {
        font-weight: normal;
    }
}

.catalog-filter {

    .catalog-filter-block-header {
        &::after {
            content: "";
            -webkit-mask-image: url(../../../../images/menu.svg);
            mask-image: url(../../../../images/menu.svg);
            display: block;
            width: 12px;
            height: 7px;
            right: 19px;
            top: 50%;
            transform: rotate( 270deg ) translate(3px, 0);
            background-color: currentColor;
        }
    }

    .catalog-filter-block-header.catalog-filter-block-header-open {
        &::after {
            transform: rotate(180deg) translate(0, 3px);
        }
    }

    .custom-input-text {
        vertical-align: bottom;
    }

    .catalog-filter-block-content {
        .color-viewer-inner,
        .color-viewer-item-block {
            border-radius: 50%;
        }
    }
}
