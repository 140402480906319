.products-view-label-inner,
.price {
    font-weight: 500;
}

.products-view-label {
    .products-view-label-inner {
        border-radius: 2px;
        padding: 3px 4px 2px;
    }
}

.color-viewer-list--iconandtext {
    .color-viewer-inner {
        border-radius: 50%;
    }
}