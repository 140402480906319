
.checkout-success--layout-default {
    .checkout-success-content {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .checkout-confirm-number {
        margin-top: 5px;
    }
}
