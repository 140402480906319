.news-subscription-checkbox {
    margin-left: 0;
    margin-right: 0;

    &__text-wrap,
    &__input-wrap {
        padding-left: 0;
        padding-right: 0;
    } 

    &__text {
        font-size: 13px;
    }
}

.registration-bonus-form-input-wrap {
    font-size: 13px;

    & > .form-field-input {
        font-size: 0;
    }

    .registration-bonus-form-text {
        font-size: 13px;
        vertical-align: middle;
    }
}

.captcha-hint {
    font-size:13px;
}

#CaptchaSource_CaptchaDiv {
    margin-bottom: 10px !important;
}

.registration-user-agreement-input-wrap {
    & > .form-field-input {
        font-size: 0;
    }
}

.registration-user-agreement-text {
    vertical-align: middle;
    font-size: 13px;
}

.registration-block-partner-btn-wrap {
    font-size: 13px;
}