@import '../../../../styles/views/news.scss';

.managers-page,
.news-page {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;

    .news-link-title {
        font-weight: 500;
        font-size: 16px;
    }

    .page-title-row {
        display: block;
        margin-left: 0;
        margin-right: 0;
        padding-left: 8px;
        padding-right: 8px;
    }

    .news-item-inner {
        padding-left: 8px;
        padding-right: 8px;
    }

    .page-title-row {
        margin-bottom: 40px;
        display: block;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;

        .news-item-title {
            padding-left: 7px;
            padding-right: 7px;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 20px;
            max-width: none;

            .main-title {
                font-size: 14px;
                margin-bottom: 0;
            }
        }
    }

    .news-categories {
        margin: 0;

        .news-menu-link.cs-bg-3 {
            background: none;
        }

        .news-menu-link {
            font-size: 13px;
            display: initial;
            padding: 5px 0;
            padding-left: 8px;
        }
    }

    .news-category-block-wrap {
        display: block;
        margin-left: 0;
        margin-right: 0;
    }

    .news-category-block {
        margin-bottom: 40px;

        .news-item {
            .news-item-img-col {
                margin-bottom: 20px;
            }

            .h3 {
                padding-right: 0;
                padding-left: 0;
            }

            .news-list-item-bDescr {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 1.2;
                padding-right: 0;
                padding-left: 0;
            }

            .news-item-href {
                padding-right: 0;
                padding-left: 0;
                margin-top: 15px;

                & > a {
                    font-size: 13px;
                }
            }
        }

        .news-item-big {
            margin-bottom: 50px;

            .h3 {
                max-height: 93px;
                overflow: hidden;
                margin-bottom: 5px;

                a {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                }
            }

            .news-list-item-bDescr {
                max-height: 150px;
                overflow: hidden;
            }
        }

        .news-item-small {
            margin-bottom: 100px;

            .h3 {
                height: 49px;
                overflow: hidden;

                a {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 13px;
                }
            }

            .news-list-item-bDescr {
                height: 65px;
                overflow: hidden;
            }
        }
    }
}


.news-page-details {
    padding-top: 0;

    .news-item-categories-wrap {
        padding: 0;
    }

    .page-title-row {
        display: block;
        margin-left: 0;
        margin-right: 0;

        .news-item-title {
            margin-bottom: 20px;
            max-width: none;
        }
    }

    .news-item {
        padding-left: 0;
        padding-right: 0;
    }

    .news-page-details-picture {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .subheader {
        margin-bottom: 10px;
    }


    .news-categories .news-menu-link {
        font-size: 13px;
        display: initial;
        padding: 5px 0;
        padding-left: 8px;
    }

    .news-categories {
        margin-left: -8px;
        margin-right: -8px;
    }

    .main-title {
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
    }

    .news-item-block {
        .news-item-content {
            font-size: 13px;
        }

        .social-btns {
            margin-top: 20px;
        }

        .link-return {
            margin-bottom: 15px;
        }
    }

    .news-page-details-picture {
        img {
            max-height: 400px;
        }
    }
}


.subscribe-block {
    padding: 0.9375rem 2.1875rem;

    .subscribe-block-field {
        input {
            padding: 0.75rem;
            font-size: 0.75rem;
        }
    }

    .subscribe-block-button {
        input {
            font-weight: 500;
            padding: 0.75rem;
            font-size: 12px;
        }
    }

    .subscribe-block-text {
        text-align: left;
        margin: 0.3125rem 0;
    }

    .subscribe-block-elements-wrap {
        display: block;
        margin: 0;
    }
}

/*.news-item-title-row .main-title {
    margin-bottom: 0;
    padding-left: 12px;
    padding-right: 12px;
}*/

.news-item-title {
    display: block;
}

.news-link-title {
    font-size: 13px;
    font-weight: bold;
}

.news-list-item-bDescr {
    font-size: 11px;
}



.subscribe-block-elements-wrap {
    margin-left: 0;
    margin-right: 0;
    justify-content: center;

    .subscribe-block-button,
    .subscribe-block-field,
    .subscribe-block-text {
        padding-left: 0;
        padding-right: 0;
        flex-basis: auto;
        max-width: none;
    }

    .subscribe-block-text {
        margin-right: 25px;
        font-size: 18px;
    }

    .subscribe-block-field {


        input {
            border: none;
            padding: 11px;
            font-size: 14px;
            margin-right: 10px;
        }
    }
}

.managers-page {
    h1 {
        padding-left: 16px;
    }
}