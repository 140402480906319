.brand-county-name {
    .btn-ghost {
        &::before {
            content: '';
            -webkit-mask-image: url(../../../../images/search.svg);
            mask-image: url(../../../../images/search.svg);
            display: block;
            width: 24px;
            height: 24px;
            background-color: currentColor;
        }
    }
}

.brands-input-search + .btn-ghost {
    &::before {
        content: '';
        -webkit-mask-image: url(../../../../images/search.svg);
        mask-image: url(../../../../images/search.svg);
        display: block;
        width: 24px;
        height: 24px;
        background-color: currentColor;
    }
}

.brand-name {
    margin-bottom: 5px;
    font-size: 16px;
}

.brand-bDescr {
    font-size: 14px;
    margin: 8px 0;
    line-height: 18px;
}

.brand-logo {
    margin-bottom: 10px;
}