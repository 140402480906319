body {
    font-family: "Rubik";
}

form.subscribe-block {
    border-radius: 0;

    .p-w-xs {
        padding: 0;
    }

    .custom-input-checkbox {
        vertical-align: bottom;
    }
}

.mobile-header {
    &__search-form-back,
    &__search-btn {
        color: currentColor;
    }
}

/*custom-input*/

.registration-block-item-checkbox {
    .form-field-input, 
    .form-field-name {
        display: inline-block;
    }
}


input.custom-input-native {
    display: none;
}

.quizzes .custom-input-checkbox,
.custom-input-checkbox {
    position: relative;
    width: 13px;
    height: 13px;
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    z-index: 1;
    vertical-align: middle;
    margin-right: 5px;
}

.quizzes .custom-input-native:checked ~ .custom-input-checkbox::after,
.custom-input-native:checked ~ .custom-input-checkbox::after {
    content: '';
    position: absolute;
    left: 1px;
    top: 0;
    mask-image: url(../../../images/checkbox.svg);
    width: 10px;
    height: 10px;
    display: block;
}

.catalog-filter {
    .catalog-filter-block-content-range {
        .ngrs-handle i {
            width: 5px;
            height: 5px;
            position: absolute;
            right: 0;
            left: 50%;
            top: 50%;
            bottom: 0;
            transform: translate(-50%, -50%);
            border-radius: 50%;
        }
    }
}


.captcha-hint {
    margin: 0 0 10px 0;
}

/*zone*/

.zone-dialog-cities .zone-dialog-items {
    display: flex;
    flex-wrap: wrap;
}

.mobile-header {
    .mobile-header__cart-count {
        font-size: 9px;
    }
} 


/*color-viewer*/

.carousel-colors-icon {
    .color-viewer-inner,
    .color-viewer-item-block {
        border-radius: 50%;
    }
}

.color-viewer {
    .color-viewer-inner {
        margin: 0;
    }
}

/**/
.autocompleter-product-image-block {
    margin: 0;
}


.shipping-item-additional .shipping-control-select {
    width: auto !important;
}

.mobile-product-view-item-image-wrap {
    position: relative;

    .products-view-wishlist {
        position: absolute;
        top: 2%;
        right: 2%;
    }
}

.products-view-wishlist .wishlist-control .svg {
    width: 32px;
    height: 32px;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #ffffff;
}

.details-availability + .products-view-wishlist {
    position: absolute;
    right: 5%;
    top: 5px;
}

/*GlorySoft_018*/

.feature-item {
    text-align: center;
    font-size: 14px;
}

.phones-number__tel {
    font-size: 1.375rem;
    font-weight: bold;
    color: #4b4f58;
    margin-top: 10px
}
.phones-number__text {
    text-align: center;
}

.footer-payment {
    text-align: center
}

.footer-payment img {
    height: 20px;
    margin-right: 15px !important
}

.footer-payment img:first-child,
.footer-payment img:nth-child(3) {
    height: 12px;
    margin: 4px 0
}

.footer-payment img:last-child {
    height: 21px;
    margin-bottom: 1px
}

.panel.no-borders {
    text-align: center;
}

.price-current {
    font-size: 16px /*24px*/;
    font-weight: 500;
}
.price-type {
    font-size: 12px;
}

.details-brand, .social-btns {
    text-align: center;
}

.products-view-price-block.products-view-price-inner {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center
}

.products-view-price-block.products-view-price-inner .block-amount-custom {
    border: 1px solid #e2e3e4;
    margin: 10px 5px;
    display: flex;
    border-radius: 5px;
    width: 85px
}

.products-view-price-block.products-view-price-inner .block-amount-custom a {
    width: 15%;
    font-size: 18px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none
}

.products-view-price-block.products-view-price-inner .block-amount-custom a:last-child {
    margin-top: -5px
}

.products-view-price-block.products-view-price-inner .block-amount-custom input {
    width: 70%;
    border: none;
    text-align: center
}

.size-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.size-list .item-size {
    padding: 7px;
    width: max-content;
    margin: 1px;
    font-size: 11px;
    border-radius: 3px;
    cursor: default;
    color: #0662c1
}

.size-list .item-size:hover,
.size-list .item-size.selected {
    background: #0662c1;
    color: #fff
}

.block-amount-custom .input-small {
    padding: 0.5rem 0.375rem 0.5rem 0.375rem;
}

/*.catalog-view__product-item-wrap .products-view-price-block {
    padding: .3125rem .625rem 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}*/
