.pagenumberer {
    padding-left: 9px;
    padding-right: 9px;

    .pagenumberer-ellipsis {
        padding-right: 7px;
        padding-left: 2px;
    }

    .pagenumberer-item {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        text-align: center;
        padding: 0;
        border-radius: 4px;
        margin-right: 4px;
    }

    .pagenumberer-next {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        text-align: center;
        border-radius: 4px;

        .icon-right-open-after {
            &:after {
                content: '';
                -webkit-mask-image: url(../../../../images/paging-active.svg);
                mask-image: url(../../../../images/paging-active.svg);
                width: 19px;
                height: 14px;
            }
        }
    }

    .pagenumberer-prev {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        text-align: center;
        border-radius: 4px;
        margin-right: 4px;

        .icon-left-open-after {
            &:after {
                content: '';
                -webkit-mask-image: url(../../../../images/paging-active.svg);
                mask-image: url(../../../../images/paging-active.svg);
                width: 19px;
                height: 14px;
                transform: rotate(180deg);
            }
        }
    }
}
