.top-shipping-row {
    font-weight: 500;
}

.details-payment {
    .products-view-price-yellow-price {
        display: inline-block;

        &::before {
            background-size: auto;
        }
    }
}

.details-colors {
    .color-viewer-list--icon {
        .color-viewer-inner,
        .color-viewer-item-block {
            border-radius: 50%;
        }
    }
}


.color-viewer-list.color-viewer-list--iconandtext {
    .color-viewer-inner {
        border-radius: 50%;
    }
}

.carousel-product-video-in-modal--wrap {
    .products-view-video-wrap {
        margin-bottom: 0;
    }
    /*.products-view-video-item,
    .video-embed {
        height: 100%;
        box-sizing: border-box;
    }*/
}

.mobile-product-main-info {
    #ONclientWrap {
        padding-left: 0;
        padding-right: 0;
    }
}

/*GlorySoft_018*/

.prodList-section,
.sale-section,
.novelty-section,
.mainpage-products {
    overflow: hidden;
}

.mainpage-products__header {
    /*margin-bottom: 16px;*/
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.66667em 1em;
}

.mainpage-products__header-title {
    font-size: 20px;
}

.prodList-section__content,
.sale-section__content,
.novelty-section__content,
.mainpage-products__content {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    align-items: flex-start;
}

.mainpage-products__content-item {
    padding-right: 13px;
    padding-left: 13px;
    border: 0;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
}
