.checkout-usertype-label {
    display: block;
}

.custom-input-text {
    vertical-align: middle;
    font-size: 13px;
}

.auth-social-link {
    font-size: 13px;
}

.checkout-bonus-card-checkbox-wrap {
    margin-left: 0;
    flex-basis: 100%;
    max-width: 100%;

    .bonus-card-icon {
        &::before {
            content: '';
            width: 15px;
            height: 21px;
            box-shadow: none;
            mask-image: url('../../../../images/giftcard.svg');
            -webkit-mask-image: url('../../../../images/giftcard.svg');
        }
    }
}

.checkout-cart-wrap {
    #ONclientWrap {
        padding-bottom: 1em;
        margin-bottom: 0;
    }
}

.bonus-card-plus-amount {
    padding: 0;
}

.form-field-control {
    .form-field-name-wrap,
    .form-field-input-wrap {
        flex-basis: 100%;
        max-width: 100%;
    }
}