.mobile-header {
    &__logo-link {
        font-weight: 500;
    }
}

.menu {
    &__city,
    &__currency {
        font-weight: 500;
    }
}
