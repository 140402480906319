.select-custom {
    select {
        background-color: #f9f9f9;
        border-color: #efeff4;
        color: #8a8a8f;
        font-size: 0.75rem;
        padding: 0.5625rem;
    }


    &::after {
        content: '';
        -webkit-mask-image: url(../../../../images/select-arrow.svg);
        mask-image: url(../../../../images/select-arrow.svg);
        background-color: #8a8a8f;
        width: 12px;
        height: 7px;
    }
}
