@import "../../../../../../../../styles/_settings.scss", "../../../../../../../../styles/functions/_util.scss";


.zone-dialog-countries {
    margin: -5px;
}

.zone-dialog-country-link {
    margin: 5px;
}

.zone-dialog-country-image,
.zone-dialog-country-name {
    vertical-align: middle;
}



.zone-dialog {

    .modal-content {
        padding: 0;
        min-height: 264px;
    }

    &-title {
        font-size: 18px;
        padding: 14px 20px;
        line-height: 130%;
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }


    &-countries {
        padding: 14px 19px 25px 19px;
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
    }

    &-country-link {
        font-size: 14px;
        padding: 8px 14px 8px 14px;
        display: flex;
        align-items: center;
        height: 48px;
        box-sizing: border-box;
        vertical-align: middle;
        border-color: transparent;
        border-width: rem-calc(1px);
        border-style: solid;
        border-radius: $border-radius-base;
        margin: 5px;
    }

    &-country-selected {
        border-color: #ccc;
    }

    &-items {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    &-item {
        display: table-cell;
        vertical-align: top;
    }


    &-item-link {
        display: block;
        border-radius: $border-radius-base;
        border: 1px solid transparent;
        font-size: 14px;
        border: none;
        font-style: normal;
        font-weight: normal;
        padding: 0;
        margin-bottom: 20px;

        &:hover {
            border-color: #dedddd;
            text-decoration: none;
        }
    }

    &-text {
        font-size: $font-size-xmedium;
        padding-right: $padding-base;
    }

    &-cities {
        padding: 32px 27px 29px 33px;
        border-top: 0;
    }


    &-autocomplete-block {
        padding: 0 19px 0 19px;
        margin-bottom: 0;
    }

    &-cities {
        padding-bottom: $padding-base;
        border-top: 1px solid #e2e3e4;
    }

    &-btn-block,
    &-search-block {
        flex-basis: 100%;
        max-width: 100%;
    }

    &-input-wrap {
        position: relative;
        margin-bottom: 0px;
        margin-top: 7px;

        &:before {
            content: '';
            background-image: url('../../../../../../images/search.svg');
            background-repeat: no-repeat;
            background-size: 24px;
            display: block;
            width: 46px;
            height: 42px;
            background-position: center;
            position: absolute;
        }

        & > input {
            box-sizing: border-box;
            border-radius: 4px;
            padding-left: 46px;
            font-size: 14px;
            padding-bottom: 12px;
        }
    }

    &-btn {
        margin-bottom: 12px;
        margin-top: 12px;
        width: 100%;
    }


    &-loading {
        text-align: center;
        padding: 40px 20px;
    }


    &-country-image {
        width: 30px;
        height: 18px;
        border-radius: 2px;
        background-repeat: no-repeat;
        margin-right: 9px;
    }

    &-country-RU {
        background-image: url(../../../../../../images/zone-img/rus.svg);
    }

    &-country-UA {
        background-image: url(../../../../../../images/zone-img/ua.svg);
    }

    &-country-BY {
        background-image: url(../../../../../../images/zone-img/by.svg);
    }

    &-country-KZ {
        background-image: url(../../../../../../images/zone-img/kz.jpg);
    }

    &-autocomplete-title {
        font-size: 16px;
        line-height: 120%;
    }

    &-autocomplete-block {
        margin-bottom: 15px;
    }

    &-text-wrap {
        flex-basis: 100%;
        max-width: 100%;
    }

    &-form-field-wrap {
        flex-basis: 100%;
        max-width: 100%;
    }
}
