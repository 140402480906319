.cart-full-mobile-result-price,
.cart-full-mobile .cart-full-mobile-item-cost,
.cart-mini-list-mobile .cart-mini-result-total-price {
    font-weight: 500;
}

.cart-mini-list-mobile .cart-mini-btn-cart, 
.cart-mini-list-mobile .cart-mini-btn-checkout {
    font-weight: normal;
}

.cart-mini-list-mobile .cart-mini-photo {
    margin: 0;
}